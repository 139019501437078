//
// user-variables.scss
// Use this to overwrite Bootstrap and Landkit variables
//

// Example of a variable override to change Landkit's background color
// Remove the "//" to comment it in and see it in action!
// $font-family-base: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Helvetica Neue", Arial, sans-serif;

@import url("https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,600,600i,700,700i,800,800i");
$font-family-base: "Open Sans", sans-serif;

$primary: #1c3a72;

$path-to-img: "../../img" !default;
$path-to-fonts: "../../fonts" !default;
