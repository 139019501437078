/* You can add global styles to this file, and also import other style files */
@media (min-width: 768px) {
  .main-content {
    margin-left: 250px;
  }
}
.tr-link {
  cursor: pointer;
}
.table-hover tbody tr:hover {
  background-color: #c3d7f677;
}
.table th:last-child,
.table td:last-child {
  text-align: right;
}
.table td {
  font-size: 0.8rem;
}
.btn-del:hover {
  color: #ffffff;
  background-color: #e63757;
  border-color: #e63757;
}
.btn-del {
  color: #6e84a3;
}
.lift:hover {
  box-shadow: unset !important;
}
.card.lift {
  box-shadow: unset;
}
.btn-outline-primary:hover,
.btn-primary {
  color: #ffffff;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIi4uL3N0eWxlcy5zY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUFBLDhFQUFBO0FBQ0E7RUFDRTtJQUNFLGtCQUFBO0VBQ0Y7QUFDRjtBQUNBO0VBQ0UsZUFBQTtBQUNGO0FBQ0E7RUFDRSwyQkFBQTtBQUVGO0FBQUE7O0VBRUUsaUJBQUE7QUFHRjtBQURBO0VBQ0UsaUJBQUE7QUFJRjtBQUZBO0VBQ0UsY0FBQTtFQUNBLHlCQUFBO0VBQ0EscUJBQUE7QUFLRjtBQUhBO0VBQ0UsY0FBQTtBQU1GO0FBSkE7RUFDRSw0QkFBQTtBQU9GO0FBTEE7RUFDRSxpQkFBQTtBQVFGO0FBTkE7O0VBRUUsY0FBQTtBQVNGIiwiZmlsZSI6InN0eWxlcy5zY3NzIiwic291cmNlc0NvbnRlbnQiOlsiLyogWW91IGNhbiBhZGQgZ2xvYmFsIHN0eWxlcyB0byB0aGlzIGZpbGUsIGFuZCBhbHNvIGltcG9ydCBvdGhlciBzdHlsZSBmaWxlcyAqL1xuQG1lZGlhIChtaW4td2lkdGg6IDc2OHB4KSB7XG4gIC5tYWluLWNvbnRlbnQge1xuICAgIG1hcmdpbi1sZWZ0OiAyNTBweDtcbiAgfVxufVxuLnRyLWxpbmsge1xuICBjdXJzb3I6IHBvaW50ZXI7XG59XG4udGFibGUtaG92ZXIgdGJvZHkgdHI6aG92ZXIge1xuICBiYWNrZ3JvdW5kLWNvbG9yOiAjYzNkN2Y2Nzc7XG59XG4udGFibGUgdGg6bGFzdC1jaGlsZCxcbi50YWJsZSB0ZDpsYXN0LWNoaWxkIHtcbiAgdGV4dC1hbGlnbjogcmlnaHQ7XG59XG4udGFibGUgdGQge1xuICBmb250LXNpemU6IDAuOHJlbTtcbn1cbi5idG4tZGVsOmhvdmVyIHtcbiAgY29sb3I6ICNmZmZmZmY7XG4gIGJhY2tncm91bmQtY29sb3I6ICNlNjM3NTc7XG4gIGJvcmRlci1jb2xvcjogI2U2Mzc1Nztcbn1cbi5idG4tZGVsIHtcbiAgY29sb3I6ICM2ZTg0YTM7XG59XG4ubGlmdDpob3ZlciB7XG4gIGJveC1zaGFkb3c6IHVuc2V0ICFpbXBvcnRhbnQ7XG59XG4uY2FyZC5saWZ0IHtcbiAgYm94LXNoYWRvdzogdW5zZXQ7XG59XG4uYnRuLW91dGxpbmUtcHJpbWFyeTpob3Zlcixcbi5idG4tcHJpbWFyeSB7XG4gIGNvbG9yOiAjZmZmZmZmO1xufVxuIl19 */