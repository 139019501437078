/* You can add global styles to this file, and also import other style files */
@media (min-width: 768px) {
  .main-content {
    margin-left: 250px;
  }
}
.tr-link {
  cursor: pointer;
}
.table-hover tbody tr:hover {
  background-color: #c3d7f677;
}
.table th:last-child,
.table td:last-child {
  text-align: right;
}
.table td {
  font-size: 0.8rem;
}
.btn-del:hover {
  color: #ffffff;
  background-color: #e63757;
  border-color: #e63757;
}
.btn-del {
  color: #6e84a3;
}
.lift:hover {
  box-shadow: unset !important;
}
.card.lift {
  box-shadow: unset;
}
.btn-outline-primary:hover,
.btn-primary {
  color: #ffffff;
}
